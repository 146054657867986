var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('edit-profile', {
    attrs: {
      "isLoading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "modal",
      fn: function fn() {
        return [_c('sygni-modal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showModal,
            expression: "showModal"
          }],
          attrs: {
            "isLoading": _vm.isLoading,
            "header": "Do you want to save the changes?"
          },
          on: {
            "cancel": _vm.changeRoute,
            "confirm": _vm.confirmUpdateProfile,
            "close": function close($event) {
              _vm.showModal = false;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('sygni-container-title', {
          staticClass: "mb-5"
        }, [_vm._v("Address data")]), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('p', {
          staticClass: "edit-profile__subheader"
        }, [_vm._v("Residential address")]), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'STREET',
            "validation": _vm.$v.profileData.residentialAddress.street
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.residentialAddress.street,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.residentialAddress, "street", $$v);
            },
            expression: "profileData.residentialAddress.street"
          }
        }), _c('div', {
          staticClass: "edit-profile__form-row"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'HOUSE NO.',
            "validation": _vm.$v.profileData.residentialAddress.houseNumber
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.residentialAddress.houseNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.residentialAddress, "houseNumber", $$v);
            },
            expression: "profileData.residentialAddress.houseNumber"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'FLAT NO.'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.residentialAddress.flatNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.residentialAddress, "flatNumber", $$v);
            },
            expression: "profileData.residentialAddress.flatNumber"
          }
        })], 1), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'CITY',
            "validation": _vm.$v.profileData.residentialAddress.city
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.residentialAddress.city,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.residentialAddress, "city", $$v);
            },
            expression: "profileData.residentialAddress.city"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'ZIP CODE',
            "validation": _vm.$v.profileData.residentialAddress.zipCode
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.residentialAddress.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.residentialAddress, "zipCode", $$v);
            },
            expression: "profileData.residentialAddress.zipCode"
          }
        }), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.globalSelects.countries,
            "options-label": 'label',
            "label": 'COUNTRY',
            "placeholder": 'Choose',
            "validation": _vm.$v.profileData.residentialAddress.country,
            "searchable": true
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.residentialAddress.country,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.residentialAddress, "country", $$v);
            },
            expression: "profileData.residentialAddress.country"
          }
        })], 1), _c('div', {
          staticClass: "edit-profile__form"
        }, [_c('div', {
          staticClass: "edit-profile__heading"
        }, [_c('p', {
          staticClass: "edit-profile__subheader"
        }, [_vm._v("Mailing address")]), _c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": "SAME AS RESIDENTIAL"
          },
          on: {
            "changedValue": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddressSameResidential,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData, "mailingAddressSameResidential", $$v);
            },
            expression: "profileData.mailingAddressSameResidential"
          }
        })], 1), !_vm.profileData.mailingAddressSameResidential ? _c('div', {
          staticClass: "edit-profile__group"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'STREET',
            "validation": _vm.$v.profileData.mailingAddress.street
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddress.street,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.mailingAddress, "street", $$v);
            },
            expression: "profileData.mailingAddress.street"
          }
        }), _c('div', {
          staticClass: "edit-profile__form-row"
        }, [_c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'HOUSE NO.',
            "validation": _vm.$v.profileData.mailingAddress.houseNumber
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddress.houseNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.mailingAddress, "houseNumber", $$v);
            },
            expression: "profileData.mailingAddress.houseNumber"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'FLAT NO.'
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddress.flatNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.mailingAddress, "flatNumber", $$v);
            },
            expression: "profileData.mailingAddress.flatNumber"
          }
        })], 1), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'CITY',
            "validation": _vm.$v.profileData.mailingAddress.city
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddress.city,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.mailingAddress, "city", $$v);
            },
            expression: "profileData.mailingAddress.city"
          }
        }), _c('sygni-input', {
          staticClass: "secondary",
          attrs: {
            "label": 'ZIP CODE',
            "validation": _vm.$v.profileData.mailingAddress.zipCode
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddress.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.mailingAddress, "zipCode", $$v);
            },
            expression: "profileData.mailingAddress.zipCode"
          }
        }), _c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.globalSelects.countries,
            "options-label": 'label',
            "label": 'COUNTRY',
            "placeholder": 'Choose',
            "validation": _vm.$v.profileData.mailingAddress.country,
            "searchable": true
          },
          on: {
            "input": _vm.handleFieldChange
          },
          model: {
            value: _vm.profileData.mailingAddress.country,
            callback: function callback($$v) {
              _vm.$set(_vm.profileData.mailingAddress, "country", $$v);
            },
            expression: "profileData.mailingAddress.country"
          }
        })], 1) : _vm._e(), _c('sygni-rounded-button', {
          class: ['filled red button secondary mt-4', !_vm.hasChanges ? 'inactive' : ''],
          on: {
            "click": function click($event) {
              return _vm.updateProfile(_vm.nextRoute);
            }
          }
        }, [_vm._v("Save changes")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }